<template>
  <b-modal v-model="showModal" title="Send Message" hide-footer size="xl" @hide="onClose">
    <b-row class="mt-2 mb-4">
      <b-col sm="8">
        <b-card class="">
          <b-card-title class="text-capitalize">Template Name</b-card-title>
          <b-card-sub-title class="mb-4">Select template you want to use.</b-card-sub-title>
          <el-select placeholder="Select template" v-model="form.template_id" @change="handlerChangeTemplate" filterable class="w-100">
            <el-option :value="item.id" v-for="(item, i) in templateList" :key="i" :label="item.template_name_str"/>
          </el-select>
          <!-- Template Header Media Variable -->
          <div v-if="selected_template.component_header && selected_template.component_header.format.toLowerCase() !== 'text'">
            <hr/>
            <b-card-title class="text-capitalize">Template Variables (Header)</b-card-title>
            <b-card-sub-title class="mb-4">Input file media to send.</b-card-sub-title>
            <b-row v-if="selected_template.component_header.format.toLowerCase() !== 'text'">
              <b-col md="3">
                <el-select @change="handlerHeaderMediaChange" v-model="form.header_params.input_type">
                  <el-option value="file" label="File input"/>
                  <el-option v-if="form.recipient_type === 'csv'" value="csv" label="CSV Column"/>
                  <el-option value="url" label="URL input"/>
                </el-select>
              </b-col>
              <b-col md="9" v-if="['csv', 'url'].includes(form.header_params.input_type)">
                <el-input v-model="form.header_params[selected_template.component_header.format.toLowerCase()]"/>
                <small class="text-danger" v-if="form.header_params.input_type === 'url' && !isValidHeaderMediaUrl">URL not valid</small>
              </b-col>
              <b-col md="9" v-if="form.header_params.input_type == 'file'">
                <b-file :accept="whiteListExtensionHeaderMedia[selected_template.component_header.format.toLowerCase()].join(',')" @change="handlerFileHeaderMedia" v-model="template_header.file"/>
              </b-col>
            </b-row>
          </div>
          <!-- End of Template Header Media Variable -->
          <!-- Template Header Text Variable -->
          <div v-if="selected_template.component_header && selected_template.component_header.format.toLowerCase() == 'text' && selected_template.component_header.header_params.param.length > 0">
            <hr/>
            <b-card-title class="text-capitalize">Template Variables (Header)</b-card-title>
            <b-card-sub-title class="mb-4">Input all variable needed.</b-card-sub-title>
            <b-row>
              <b-col md="3">
                <el-select v-model="form.header_params.input_type">
                  <el-option v-if="form.recipient_type === 'csv'" value="csv" label="CSV Column"/>
                  <el-option value="text" label="Static Text"/>
                </el-select>
              </b-col>
              <b-col md="9">
                <el-input v-model="form.header_params.text" placeholder="Input parameter value"/>
              </b-col>
            </b-row>
          </div>
          <!-- End of Template Header Text Variable -->
          <!-- Template Body Variable -->
          <div v-if="form.body_params.length > 0">
            <hr/>
            <b-card-title class="text-capitalize">Template Variables (Body)</b-card-title>
            <b-card-sub-title class="mb-4">Input all variable needed.</b-card-sub-title>
            <b-row class="mb-2" v-for="(item, i) in selected_template.body_params" :key="i">
              <b-col md="3">
                <el-select v-model="form.body_params[i].type">
                  <el-option v-if="form.recipient_type === 'csv'" value="csv" label="CSV Column"/>
                  <el-option value="text" label="Static Text"/>
                </el-select>
              </b-col>
              <b-col md="9">
                <el-input v-model="form.body_params[i].text" placeholder="Input parameter value"/>
              </b-col>
            </b-row>
          </div>
          <!-- End of Template Body Variable -->
          <!-- Template Button Variable -->
          <div v-if="form.cta_params.length > 0">
            <hr/>
            <b-card-title class="text-capitalize">Template Variables (Button)</b-card-title>
            <b-card-sub-title class="mb-4">Input all variable needed.</b-card-sub-title>
            <b-row class="mb-2" v-for="(item, i) in form.cta_params" :key="i">
              <b-col md="3">
                <el-select v-model="form.cta_params[i].type">
                  <el-option v-if="form.recipient_type === 'csv'" value="csv" label="CSV Column"/>
                  <el-option value="text" label="Static Text"/>
                </el-select>
              </b-col>
              <b-col md="9">
                <el-input v-model="form.cta_params[i].text" placeholder="Input parameter value"/>
              </b-col>
            </b-row>
          </div>
          <!-- End of Template Button Variable -->
        </b-card>
      </b-col>
      <b-col sm="4">
        <div class="screen">
          <div class="screen-container">
            <div class="chat">
              <div class="chat-container">
                <div class="conversation">
                  <!-- <div class="conversation-container vh-100"> -->
                  <div class="conversation-container">
                    <h6 class="mt-2">Preview</h6>
                    <div class="preview-message received w-100">
                      <span v-if="previewHeaderContent" style="font-size: 14px; font-weight: bold">{{previewHeaderContent}}<br/></span>
                      <div class="header-media-area" v-if="selected_template.component_header && selected_template.component_header.format.toLowerCase() !== 'text'">
                        <div v-if="!template_header.base64" class="header-media-content" :style="'background-image: url('+ bg_media[selected_template.component_header.format.toLowerCase()] +')'"/>
                        <div v-else-if="template_header.base64 && selected_template.component_header.format.toLowerCase() == 'image'" class="header-media-filled" :style="'background-image: url('+ template_header.base64 +')'"/>
                        <div v-else-if="template_header.base64 && selected_template.component_header.format.toLowerCase() == 'video'">
                          <iframe width="240" :src="template_header.base64"/>
                        </div>
                        <div v-else-if="template_header.base64 && selected_template.component_header.format.toLowerCase() == 'document'" class="header-media-content" :style="'background-image: url('+ bg_media.document +')'"/>
                      </div>
                      <p class="preview-body mt-2" v-html="previewContent"/>
                      <p class="mt-2 preview-footer" v-if="selected_template.component_footer">{{selected_template.component_footer.text}}<br/></p>
                      <span class="metadata"><span class="time">{{ moment().format('HH:mm') }}</span></span>
                      <div class="cta-area w-100" v-if="selected_template.component_buttons && selected_template.component_buttons.buttons[0].type.toLowerCase() !== 'quick_reply'">
                        <div class="cta-button" v-for="(item, i) in selected_template.component_buttons.buttons" :key="i">
                          <p><font-awesome :icon="item.type.toLowerCase() === 'url' ? 'external-link-alt' : 'phone-alt'"/>&nbsp;{{ item.text }}</p>
                        </div>
                      </div>
                    </div>
                    <div class="button-area w-100" style="margin-top: -1em" v-if="selected_template.component_buttons && selected_template.component_buttons.buttons[0].type.toLowerCase() === 'quick_reply'">
                      <div class="row-button">
                        <div class="col-button" v-for="(item, i) in selected_template.component_buttons.buttons" :key="i">
                          {{ item.text }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <div class="d-flex flex-row-reverse">
      <el-button @click="sendNotification" :disabled="!isValidStep2" size="small" type="primary" class="ml-2">Send</el-button>
      <el-button @click="onClose" size="small">Close</el-button>
    </div>
  </b-modal>
</template>
<script>
import { isEmpty, escapeRegExp } from 'lodash';
import axios from 'axios';
import base64File from '@/library/base64File';
import moment from 'moment';
import conversationsAPI from '@/api/conversations';
import popupErrorMessages from '@/library/popup-error-messages';

export default {
  name: 'ConvoStartTicket',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    channel_id: {
      type: String,
    },
    contact_id: {
      type: String,
    },
  },
  data() {
    return {
      showModal: false,
      // page: 1,
      moment,
      loaderStack: 0,
      loader: null,
      templateList: [],
      bg_media: {
        image: 'https://api.media.makna.ai/makna-media/template-header-image.png',
        document: 'https://api.media.makna.ai/makna-media/template-header-document.png',
        video: 'https://api.media.makna.ai/makna-media/template-header-video.png',
      },
      form: {
        contact_id: '',
        template_name: '',
        template_language: '',
        // channel_id: '',
        body_params: [],
        header_params: {},
        cta_params: [],
      },
      selected_template: {
        component_header: null,
        component_body: null,
        component_footer: null,
        component_buttons: null,
      },
      template_header: {
        file: null,
        base64: '',
      },
      whiteListExtensionHeaderMedia: {
        image: ['.jpg', '.png'],
        video: ['.mp4'],
        document: ['.pdf'],
      },
    };
  },
  computed: {
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
    isValidStep2() {
      let is_body_valid = true;
      let is_header_valid = true;
      let is_button_valid = true;
      if (this.form.body_params.length > 0) {
        const findEmpty = this.form.body_params.filter((v) => !v.text);
        if (findEmpty.length) {
          is_body_valid = false;
        }
      }
      if (this.form.header_params?.type) {
        const { type, input_type } = this.form.header_params;
        if (input_type === 'file' && !this.template_header.file) {
          is_header_valid = false;
        } else if (type === 'text' && !this.form.header_params[type] && this.selected_template.component_header?.header_params?.param?.length > 0) {
          is_header_valid = false;
        }
        if (!this.isValidHeaderMediaUrl) {
          is_header_valid = false;
        }
      }

      if (this.form.cta_params.length > 0) {
        const findEmpty = this.form.cta_params.filter((v) => !v.text);
        if (findEmpty.length) {
          is_button_valid = false;
        }
      }
      return is_header_valid && is_body_valid && is_button_valid && this.form.template_id;
    },
    isValidHeaderMediaUrl() {
      /* eslint-disable no-useless-escape */
      const regex_url = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/ig;
      const { type, input_type } = this.form.header_params;
      if (input_type === 'url') {
        return regex_url.test(this.form.header_params[type]);
      }
      return true;
    },
    previewHeaderContent() {
      if (this.selected_template.component_header?.format.toLowerCase() === 'text') {
        if (this.form.header_params.text) {
          return this.selected_template.component_header?.text.replace('{{1}}', this.form.header_params.text);
        }
        return this.selected_template.component_header?.text;
      }
      return '';
    },
    previewContent() {
      if (this.selected_template.component_body) {
        let string = this.selected_template.component_body.text;
        const htmlFormat = [
          { symbol: '*', tag: 'b' },
          { symbol: '_', tag: 'em' },
          { symbol: '~', tag: 'del' },
          { symbol: '```', tag: 'code' },
        ];

        this.selected_template.body_params.forEach((v, i) => {
          const replaceRegex = new RegExp(escapeRegExp(v), 'ig');
          const replace_text = this.form.body_params[i].text;
          if (replace_text) {
            string = string.replace(replaceRegex, replace_text);
          }
        });

        htmlFormat.forEach(({ symbol, tag }) => {
          if (!string) return;
          const regex = new RegExp(`\\${symbol}([^${symbol}]*)\\${symbol}`, 'gm');
          const match = string.match(regex);
          if (!match) return;
          match.forEach((m) => {
            let formatted = m;
            /* eslint-disable no-plusplus */
            for (let i = 0; i < 2; i++) {
              formatted = formatted.replace(symbol, `<${i > 0 ? '/' : ''}${tag}>`);
            }
            string = string.replace(m, formatted);
          });
        });
        return string;
      }
      return '';
    },
  },
  methods: {
    onClose() {
      this.$emit('onClose');
    },
    resetChat() {
      this.$emit('resetChat');
    },
    showLoader() {
      if (this.loaderStack === 0) {
        this.loader = this.$loading.show();
      }
      this.loaderStack += 1;
    },
    hideLoader() {
      this.loaderStack -= 1;
      if (this.loaderStack === 0) {
        this.loader.hide();
      }
    },
    async getTemplate() {
      await conversationsAPI.getStartTicketTemplates(this.activeWorkspace._id, this.channel_id)
        .then(async (res) => {
          await popupErrorMessages(res);
          this.templateList = res.data.templates.filter((v) => v.status?.toLowerCase() === 'approved').map((v) => {
            v.template_name_str = `${v.name} (${v.language})`;
            return v;
          });
        })
        .catch(() => {});
    },
    handlerChangeTemplate(value) {
      this.template_header = {
        file: null,
        base64: '',
      };
      this.form.header_params = {};
      this.form.body_params = [];
      this.form.cta_params = [];
      this.selected_template = this.templateList.find((v) => v.id === value);
      this.form.template_name = this.selected_template.name;
      this.form.template_language = this.selected_template.language;
      const component_body = this.selected_template.components.find((v) => v.type.toLowerCase() === 'body');
      const component_header = this.selected_template.components.find((v) => v.type.toLowerCase() === 'header');
      if (component_header) {
        const format = component_header?.format?.toLowerCase();
        this.form.header_params = {
          input_type: 'file',
          [format]: '',
          type: format,
        };
        component_header.header_params = {};
      }
      if (component_header?.format?.toLowerCase() === 'text') {
        this.form.header_params = {
          input_type: 'text',
          type: 'text',
          text: '',
        };
        component_header.header_params = {
          type: 'text',
          param: component_header.text.match(/({{\d}})/ig) || [],
        };
      }
      const component_footer = this.selected_template.components.find((v) => v.type.toLowerCase() === 'footer');
      const component_buttons = this.selected_template.components.find((v) => v.type.toLowerCase() === 'buttons');
      if (component_buttons) {
        const btn_url = component_buttons.buttons.find((v) => v.type.toLowerCase() === 'url');
        if (btn_url) {
          const has_dynamic = btn_url.url.match(/({{\d}})/ig);
          if (has_dynamic) {
            this.form.cta_params = [
              {
                button: 'url',
                type: 'text',
                text: '',
              },
            ];
          }
        }
      }
      const body_params = component_body.text.match(/({{\d}})/ig) || [];
      if (body_params) this.form.body_params = body_params.map(() => ({ type: 'text', text: '' }));
      /* eslint-disable prefer-object-spread */
      this.selected_template = Object.assign({
        component_body,
        body_params,
        component_header,
        component_footer,
        component_buttons,
      }, this.selected_template);
    },
    async handlerFileHeaderMedia(event) {
      const file = event.target.files[0];
      if (file) {
        this.template_header.base64 = await base64File(file);
      }
      // this.validateContentTypeMediaURL();
    },
    handlerHeaderMediaChange() {
      this.template_header = {
        base64: '',
        file: null,
      };
    },
    async validateContentTypeMediaURL() {
      const { type } = this.form.header_params;
      let valid_content_type = ['image/jpeg', 'image/jpg', 'image/png'];
      if (type === 'video') {
        valid_content_type = ['video/mp4', 'video/3gpp'];
      } else if (type === 'document') {
        return true;
      }
      try {
        const response = await axios({
          url: this.form.header_params[type],
          responseType: 'arraybuffer',
          responseEncoding: 'binary',
        });
        const isValid = valid_content_type.includes(response.headers['content-type'].split(';')[0]);
        if (isValid) {
          this.template_header.base64 = this.form.header_params[type];
        }
        return isValid;
      } catch (error) {
        console.log(error);
        return false;
      }
    },
    resetAll() {
      this.form = {
        contact_id: '',
        template_name: '',
        template_language: '',
        body_params: [],
        header_params: {},
        cta_params: [],
      };
      this.templateList = [];
      this.template_header = {
        file: null,
        base64: '',
      };
      // this.recipient_file = null;
      this.selected_template = {
        component_header: null,
        component_body: null,
        component_footer: null,
        component_buttons: null,
      };
    },
    async sendNotification() {
      // this.showLoader();
      // const is_valid = await this.validateContentTypeMediaURL();
      // this.hideLoader();
      // if (!is_valid) {
      //   this.$message({
      //     type: 'warning',
      //     message: 'Media URL is not valid',
      //   });
      //   return;
      // }
      this.form.contact_id = this.contact_id;
      if (this.form.header_params?.input_type === 'file' && this.template_header?.base64) {
        this.form.header_params[this.form.header_params.type] = this.template_header.base64;
      }
      // console.log(this.form);
      // console.log(this.template_header);
      this.$confirm(this.$t('conversations.reach_ticket.confirmation'), this.$t('general.confirmation'), {
        confirmButtonText: this.$t('general.yes'),
        cancelButtonText: this.$t('general.no'),
        type: 'warning',
        center: true,
        /* eslint-disable no-param-reassign */
        beforeClose: async (action, instance, cb) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            // const form_data = JSON.parse(JSON.stringify(this.form));
            // delete form_data.cta_params;
            const response = await conversationsAPI.startTicket({
              workspace_id: this.activeWorkspace._id,
              channel_id: this.channel_id,
              data: this.form,
              // data: form_data,
            }).catch(() => {});
            instance.confirmButtonLoading = false;
            await popupErrorMessages(response);
            // console.log(response);
            const errormsg = response.error;
            if (isEmpty(errormsg)) {
              this.$message({
                type: 'success',
                message: this.$t('conversations.reach_ticket.success'),
                duration: 30 * 1000,
                showClose: true,
              });
              this.resetAll();
              cb();
              this.onClose();
              this.resetChat();
            } else {
              this.$message({
                title: this.$t('general.error.general'),
                type: 'error',
                message: response.error,
                duration: 30 * 1000,
                showClose: true,
              });
            }
          }
          cb();
        },
      });
    },
  },
  watch: {
    async show() {
      this.showModal = this.show;
      if (this.show) {
        this.showLoader();
        this.resetAll();
        await this.getTemplate();
        this.hideLoader();
      }
    },
  },
};
</script>

<style scoped>
/* .dropify-wrapper .dropify-message p {
  font-size: 16px;
  color: #686868;
} */
.screen {
  text-align: left;
}
.screen-container {
  height: 100%;
}

/* Chat */

.chat {
  height: calc(100% - 69px);
}

.chat-container {
  height: 100%;
}

/* Conversation */

.conversation {
  height: calc(100% - 12px);
  position: relative;
  background: #efe7dd url("https://cloud.githubusercontent.com/assets/398893/15136779/4e765036-1639-11e6-9201-67e728e86f39.jpg") repeat;
  z-index: 0;
}

.conversation ::-webkit-scrollbar {
  transition: all .5s;
  width: 5px;
  height: 1px;
  z-index: 10;
}

.conversation ::-webkit-scrollbar-track {
  background: transparent;
}

.conversation ::-webkit-scrollbar-thumb {
  background: #b3ada7;
}

.conversation .conversation-container {
  /* height: calc(100% - 68px); */
  min-height: 200px;
  /* box-shadow: inset 0 10px 10px -10px #000000; */
  overflow-x: hidden;
  padding: 0 16px;
  margin-bottom: 5px;
}

.conversation .conversation-container:after {
  content: "";
  display: table;
  clear: both;
}

/* Messages */

.preview-message {
  color: #000;
  clear: both;
  line-height: 18px;
  font-size: 12px;
  padding: 8px;
  position: relative;
  margin: 8px 0;
  max-width: 265px;
  word-wrap: break-word;
  z-index: -1;
}

.preview-message:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
}

.button-area {
  color: #000;
  clear: both;
  line-height: 18px;
  font-size: 12px;
  padding: 8px 0px;
  position: relative;
  word-wrap: break-word;
  z-index: -1;
  max-width: 265px;
  border-radius: 5px;
  float: left;
}

.reply-button {
  color: #5591EC;
  clear: both;
  line-height: 18px;
  font-size: 12px;
  padding: 8px;
  position: relative;
  word-wrap: break-word;
  z-index: -1;
  background: #fff;
  border-radius: 5px;
  float: left;
  text-align: center;
}

.cta-area {
  border-top: 1px solid #dadde1;
  /* margin: 0 7px 0 9px; */
  margin-top: 8px;
  float: right;
  background: #fff;
}

.cta-button {
  color: #00a5f4;
  font-size: 12px;
  height: 24px;
  line-height: 20px;
  padding: 0 16px;
  white-space: pre-wrap;
  text-align: center;
  justify-content: center;
  flex-direction: row;
  display: flex;
  word-wrap: break-word;
  margin-top: 5%;
}

.header-media-area {
  padding: 3px 3px 0 3px;
}

.header-media-content {
  background-color: #ccd0d5;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 80px 80px;
  border-radius: 4px;
  box-sizing: border-box;
}

.header-media-filled {
  background-color: #ccd0d5;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  border-radius: 4px;
  box-sizing: border-box;
  width: 240px;
}

.header-media-content::after {
  content: '';
  display: block;
  padding-top: calc(100% * (1 / 1.91));
}

.header-media-filled::after {
  content: '';
  display: block;
  padding-top: calc(100% * (1 / 1.91));
}

.header-media-image {
  background-image: url('https://api.media.makna.ai/makna-media/template-header-image.png');
}

.metadata {
  display: inline-block;
  float: right;
  padding: 0 0 0 7px;
  position: relative;
  bottom: -4px;
}

.metadata .time {
  color: rgba(0, 0, 0, .45);
  font-size: 11px;
  display: inline-block;
}

.metadata .tick {
  display: inline-block;
  margin-left: 2px;
  position: relative;
  top: 4px;
  height: 16px;
  width: 16px;
}

.metadata .tick svg {
  position: absolute;
  transition: .5s ease-in-out;
}

.metadata .tick svg:first-child {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform: perspective(800px) rotateY(180deg);
          transform: perspective(800px) rotateY(180deg);
}

.metadata .tick svg:last-child {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform: perspective(800px) rotateY(0deg);
          transform: perspective(800px) rotateY(0deg);
}

.metadata .tick-animation svg:first-child {
  -webkit-transform: perspective(800px) rotateY(0);
          transform: perspective(800px) rotateY(0);
}

.metadata .tick-animation svg:last-child {
  -webkit-transform: perspective(800px) rotateY(-179.9deg);
          transform: perspective(800px) rotateY(-179.9deg);
}

.preview-message:first-child {
  margin: 16px 0 8px;
}

.preview-message.received {
  background: #fff;
  border-radius: 0px 5px 5px 5px;
  float: left;
}

.preview-message.received .metadata {
  padding: 0 0 0 16px;
}

.preview-message.received:after {
  border-width: 0px 10px 10px 0;
  border-color: transparent #fff transparent transparent;
  top: 0;
  left: -10px;
}

.preview-message.sent {
  background: #e1ffc7;
  border-radius: 5px 0px 5px 5px;
  float: right;
}

.preview-message.sent:after {
  border-width: 0px 0 10px 10px;
  border-color: transparent transparent transparent #e1ffc7;
  top: 0;
  right: -10px;
}

.preview-footer {
  font-size: 11px;
  color: rgba(0, 0, 0, .45);
  word-wrap: break-word;
  white-space: pre-wrap;
}

.preview-body {
  font-size: 12px;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.row-button {
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  display: flex;
}

.col-button {
  background-color: #fff;
  border-radius: 7.5px;
  box-shadow: 0 1px 0.5px rgb(0 0 0 / 15%);
  box-sizing: border-box;
  flex-grow: 1;
  margin: 2px 0 0 2px;
  min-width: calc(50% - 2px);
  color: #00a5f4;
  font-size: 14px;
  height: 34px;
  line-height: 20px;
  padding: 0 16px;
  white-space: pre-wrap;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  display: flex;
}

/* Small Screens */

@media (max-width: 768px) {
  .marvel-device.nexus5 {
    border-radius: 0;
    flex: none;
    padding: 0;
    max-width: none;
    overflow: hidden;
    height: 100%;
    width: 100%;
  }

  .marvel-device > .screen .chat {
    visibility: visible;
  }

  .marvel-device {
    visibility: hidden;
  }

  .screen-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  /* .conversation {
    height: calc(100vh - 55px);
  }
  .conversation .conversation-container {
    height: calc(100vh - 120px);
  } */
}
</style>
